import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environment/environment';
import { AppModule } from '@feedback/app.module';
import { throwError } from 'rxjs';

if (environment.IS_PRODUCTION) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err: Error) => throwError(err));
